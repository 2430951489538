import request from "@/utils/request";
import store from "../store";

export function getMeetingList(param) {
  let isAdmin = store.state.user.IsAdmin + "" === "true";
  return request.get(`/mobile/${isAdmin ? "admin" : "user"}/workorder/page`, {
    params: { ...param },
  });
}

export function getMeetingDetails(param) {
  let isAdmin = store.state.user.IsAdmin + "" === "true";
  return request.get(
    `/mobile/${isAdmin ? "admin" : "user"}/workorder/${param.workorderId}`,
    {}
  );
}

export function addMeeting(data) {
  return request({
    url: "/mobile/admin/workorder",
    method: "post",
    data: {
      ...data,
    },
  });
}

export function getMeetingRoomList() {
  return request.get("/mobile/meeting/room");
}

export function cancelMeeting(param) {
  return request.put(`/mobile/admin/workorder/cancel/${param.workorderId}`, {
    reason: param.reason,
  });
}

export function changeMeetingRoom(data) {
  let isAdmin = store.state.user.IsAdmin + "" === "true";
  return request.put(
    `/mobile/${isAdmin ? "admin" : "user"}/workorder/change/${
      data.workorderId
    }`,
    {
      ...data,
    }
  );
}

export function recommitMeeting(param) {
  return request.put(`/mobile/admin/workorder/redeploy/${param.workorderId}`, {
    sids: param.sids,
  });
}

export function atartMeeting(data) {
  return request({
    url: "/mobile/user/workorder/start/before/" + data.workorderId,
    method: "post",
  });
}

export function getRoomPreparingInfo(param) {
  return request.get(`/mobile/meeting/room/` + param.roomId);
}

export function UploadPic(file) {
  let formdata = new FormData();
  formdata.append("uploadFile", file);
  return request({
    url: "/upload/image",
    method: "post",
    data: formdata,
  });
}

export function addBeforeSchedule(data) {
  return request({
    url: "/mobile/user/workorder/schedule/before",
    method: "post",
    data: {
      ...data,
    },
  });
}

export function editMeeting(data) {
  return request({
    url: "/mobile/admin/workorder/" + data.workorderId,
    method: "put",
    data: {
      ...data,
    },
  });
}

export function getDuringSchedule(data) {
  let isAdmin = store.state.user.IsAdmin + "" === "true";
  return request({
    url: `/mobile/${isAdmin ? "admin" : "user"}/workorder/schedule/${
      data.workorderId
    }/during`,
    method: "get",
  });
}

export function addDuringSchedule(data) {
  return request({
    url: "/mobile/user/workorder/schedule/during",
    method: "post",
    data: {
      ...data,
    },
  });
}

export function addEndingSchedule(data) {
  return request({
    url: "/mobile/user/workorder/schedule/after",
    method: "post",
    data: {
      ...data,
    },
  });
}

export function dealDuringSchedule(data) {
  return request({
    url: "/mobile/user/workorder/schedule/during/deal/" + data.duringId,
    method: "post",
    data: {
      ...data,
    },
  });
}

export function finishMeeting(data) {
  return request({
    url: "/mobile/user/workorder/start/after/" + data.workorderId,
    method: "post",
  });
}

export function addMeetingSupport(data) {
  return request({
    url: "/mobile/user/workorder/schedule/support",
    method: "post",
    data: {
      ...data,
    },
  });
}

export function getMeetingProgressDetails(data) {
  let isAdmin = store.state.user.IsAdmin + "" === "true";
  return request({
    url:
      `/mobile/${isAdmin ? "admin" : "user"}/workorder/schedule/` +
      data.scheduleId,
    method: "get",
  });
}

export function sendMeeting(data) {
  return request({
    url: "/mobile/admin/workorder/send",
    method: "post",
    data: {
      ...data,
    },
  });
}

export function editSendMeeting(data) {
  return request({
    url: "/mobile/admin/workorder/send/" + data.workorderId,
    method: "put",
    data: {
      ...data,
    },
  });
}

export function getProblemDetails(data) {
  return request({
    url: "/mobile/user/workorder/schedule/during/" + data.scheduleId,
    method: "get",
  });
}
