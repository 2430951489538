<!--  -->
<template>
  <div class="pageWrapper overFlowWrapper">
    <van-form ref="formRef" style="margin-top:10px;overflow:scroll">
      <div class="problemInnerWrapper">
        <div class="singleDetail">
          <div class="singleDetailTitle">
            问题标题
          </div>
          <div class="detailContent">
            <van-field
              :disabled="isEditingProblem"
              v-model="currentProblem.questionTitle"
              name="问题标题"
              placeholder="问题标题"
              :rules="[{ required: true, message: '请填写问题标题' }]"
            />
          </div>
        </div>

        <div class="singleDetail">
          <div class="singleDetailTitle">
            详细描述
          </div>
          <div class="detailContent">
            <van-field
              :disabled="isEditingProblem"
              v-model="currentProblem.questionDetail"
              type="textarea"
              name="详细描述"
              placeholder="详细描述"
              :rules="[{ required: true, message: '请填写详细描述' }]"
            />
          </div>
        </div>
        <div class="singleDetail radioFloatWrapper">
          <div class="singleDetailTitle" style="float:left">
            处理情况
          </div>
          <div style="float:right">
            <van-radio-group
              v-model="currentProblem.dispose"
              :disabled="isEditingProblem"
              direction="horizontal"
            >
              <van-radio :name="true" checked-color="#2BB7B3">已处理</van-radio>
              <van-radio :name="false" checked-color="#2BB7B3"
                >未处理</van-radio
              >
            </van-radio-group>
          </div>
        </div>

        <div class="singleDetail">
          <div class="singleDetailTitle">
            处理方法
          </div>
          <div class="detailContent">
            <van-field
              v-model="currentProblem.disposeWay"
              type="textarea"
              name="处理方法"
              placeholder="处理方法"
              :required="currentProblem.dispose"
              :rules="
                currentProblem.dispose
                  ? [{ required: true, message: '请填写处理方法' }]
                  : []
              "
            />
          </div>
        </div>

        <div class="singleDetail radioFloatWrapper">
          <div class="singleDetailTitle" style="float:left">
            严重等级
          </div>
          <div style="float:right">
            <van-radio-group
              v-model="currentProblem.severityLevel"
              direction="horizontal"
              :disabled="isEditingProblem"
            >
              <van-radio :name="1" checked-color="#DC1414">1级</van-radio>
              <van-radio :name="2" checked-color="#ED6C00">2级</van-radio>
              <van-radio :name="3" checked-color="#2BB7B3">3级</van-radio>
            </van-radio-group>
          </div>
        </div>

        <div class="titleBar">
          现场照片
        </div>
        <div class="uploadWrapper">
          <div class="picUpload">
            <van-uploader
              v-model="fileList"
              :multiple="false"
              :before-read="beforeRead"
              :after-read="afterRead"
              @delete="beforeDelete"
            >
              <!-- <template #preview-cover="{ file }">
                                <div class="preview-cover van-ellipsis">{{ file.name }}</div>
                            </template> -->
            </van-uploader>
          </div>
        </div>

        <div class="singleDetail" style="margin-bottom:80px">
          <van-checkbox
            v-model="currentProblem.notify"
            :disabled="isEditingProblem"
            >是否告知主管领导</van-checkbox
          >
        </div>
      </div>
      <div
        class="bottomButtonWrapper"
        style="bottom:0;height:50px;display:flex;align-"
      >
        <van-button
          v-if="!isEditingProblem"
          @click="handleSubmit"
          class="bottomButton"
          plain
          type="primary"
          size="small"
          style="color:#2bb7b3;font-size:.8rem;font-weight:500;border: 1px solid #2bb7b3;border-radius:5px"
          >提交</van-button
        >
        <van-button
          v-else
          @click="handleDealProblem"
          class="bottomButton"
          plain
          type="primary"
          size="small"
          style="color:#2bb7b3;font-size:.8rem;font-weight:500;border: 1px solid #2bb7b3;border-radius:5px"
          >处理</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  UploadPic,
  getProblemDetails,
  dealDuringSchedule,
  addDuringSchedule,
} from "@/api/meetingList";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      currentProblem: {
        dispose: false,
        disposeWay: "",
        imageIds: [],
        notify: true,
        questionDetail: "",
        questionTitle: "",
        severityLevel: null,
        workorderId: 0,
      },
      fileList: [],
    };
  },
  //监听属性 类似于data概念
  computed: {
    currentMeeting() {
      return this.$store.state.currentMeeting.meeting;
    },
    isEditingProblem() {
      return this.$store.state.currentMeeting.isEditingProblem;
    },
    currentProblemFormStore() {
      return this.$store.state.currentMeeting.currentProblem;
    },
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    handleDealProblem() {
      console.log(this.currentProblem);
      let param = {
        ...this.currentProblem,
        duringId: this.currentProblem.id,
        dispose: true,
      };
      dealDuringSchedule(param)
        .then((res) => {
          if (res.code === 10000) {
            this.$store.commit("SetNavBarTitle", "会中工作");
            this.$router.push("/inprogressSchedule");
          } else {
            this.$notify({ type: "warning", message: res.message });
          }
        })
        .catch((error) => {
          this.$notify({ type: "warning", message: error.message });
        });
    },
    getproblemDetail() {
      getProblemDetails({ scheduleId: this.currentProblemFormStore.id }).then(
        (res) => {
          if (res.code === 10000) {
            console.log(res.data);
            this.currentProblem = res.data;
            this.fileList = this.currentProblem.images
              ? this.currentProblem.images.map((item, index) => {
                  item.url = item.imageUrl;
                  item.file = { index: index };
                  return item;
                })
              : [];
            this.currentProblem.imageIds = this.currentProblem.images
              ? this.currentProblem.images.map((item) => {
                  return item.imageId;
                })
              : [];
          } else {
            this.$notify({ type: "warning", message: res.message });
          }
        }
      );
    },
    handleSubmit() {
      console.log("submit", this.currentProblem);
      if (!this.currentProblem.severityLevel) {
        this.$notify({ type: "warning", message: "请选择严重等级" });
        return;
      }

      this.$refs.formRef
        .validate()
        .then(() => {
          this.currentProblem.workorderId = this.currentMeeting.workorderId;
          addDuringSchedule(this.currentProblem)
            .then((res) => {
              if (res.code === 10000) {
                this.$notify({ type: "success", message: "操作成功" });
                this.$store.commit("SetNavBarTitle", "会中工作");
                this.$router.push("/inprogressSchedule");
              } else {
                this.$notify({ type: "warning", message: res.message });
              }
            })
            .catch((error) => {
              this.$notify({ type: "warning", message: error.message });
            });
        })
        .catch(() => {});
    },
    beforeRead(file) {
      file.index = this.fileList.length;
      return true;
    },
    beforeDelete(file) {
      console.log(file, this.currentProblem.imageIds);
      this.currentProblem.imageIds = this.currentProblem.imageIds.filter(
        (item, index) => {
          return index !== file.file.index;
        }
      );
      //   重新排index
      console.log(this.fileList);
      this.fileList = this.fileList.map((item, index) => {
        ``;
        item.file.index = index;
        return item;
      });
    },
    afterRead(file) {
      UploadPic(file.file)
        .then((res) => {
          if (res.code === 10000 && res.httpStatus === 200) {
            console.log(this.fileList, this.currentProblem);
            this.currentProblem.imageIds.push(res.data.imageId);

            this.$notify({ type: "success", message: "上传成功" });
          } else {
            this.$notify({ type: "warning", message: res.message });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    console.log(this.isEditingProblem, this.currentProblemFormStore);
    if (this.isEditingProblem) {
      this.getproblemDetail();
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("SetNavBarTitle", to.meta.name);
    next();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style>
/* @import url(); 引入公共css类*/
.singleDetail {
  background-color: #fff;
  border-radius: 3px;
  padding: 5px;
  padding-left: 10px;
  margin-bottom: 15px;
}
.singleDetailTitle {
  border-left: 2px solid #2bb7b3;
  padding-left: 5px;
  font-size: 0.6rem;
}
.detailContent .van-cell {
  padding-left: 5px;
}
.radioFloatWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;
}
.overFlowWrapper {
  height: 100%;
  overflow: scroll;
}
.uploadWrapper {
  background-color: #fff;
  margin: 10px 0;
  border-radius: 3px;
  padding: 0.5rem;
}

.uploadWrapper .van-uploader__upload {
  margin: 0;
}
</style>
